import { get, post, put } from '@/service/request'
// 查询购物车
// export const getShoppingCart = (params) =>
//   get(`/goods/h5/shopping-cart`, params)
// 查询购物车
export const getShoppingCart = (params) =>
  post(`/goods/h5/shopping-cartV1`, params)

// 更新购物车
export const updateShoppingCart = (params) =>
  put(`/goods/h5/shopping-cart`, params)

// 添加商品
export const addShoppingCart = (params) =>
  post(`/goods/h5/shopping-cart`, params)

// 根据客户code查标签
export const getCustomerTagByCode = (custCode) =>
  get(`/customer/h5/customerTag/tag/custCode/${custCode}`)

//通过商品编码获取商品信息
export const queryPromotionGoods = (params) =>
  post(`/goods/h5/shopping-cart/goods`, params)

//通过商品编码获取商品信息
export const queryOptionalGoods = (objQuery, page, size) =>
  post(`/dp/h5/DpSocPromotion/goods/optional`, {
    objQuery,
    page,
    size,
  })

// 验证安全库存
export function checkSafeStockForBend(data) {
  return post(`/goods/h5/saleNewModeLimit/checkSafeStockForBend`, data, {})
}

export const giftPromotionJoin = (params) =>
  post(`/dp/h5/bcompany/gift/promotion/join`, params)

export const giftPromotionJoinCEnd = (params) =>
  post(`/dp/h5/bcompany/gift/promotion/C_END/join`, params)
